export default [
    "hello",
    "hello there",
    "hi",
    "hey",
    "g'day",
    "howdy",
    "peek-a-boo",
    "hey there",
    "yo"
];
