export default [
    {
        name: "Home",
        path: "/"
    },
    {
        name: "Skills",
        path: "/skills"
    }
];
