const config = {
    clicker: {
        version: 1
    },
    background: {
        // Determines the resolution multiplier of the canvas with stars
        // !!! ATTENTION: DO NOT SET A HIGH VALUE, STAR BACKGROUND IS QUITE RESOURCE-INTENSIVE AND MAY SLOW DOWN ON WEAK DEVICES
        scale: 0.5
    },
    nickname: "mirdukkkkk"
};

export default config;
