const art = [
    "                       :==--..                                             ",
    "                      .-=#+++=-:.                                          ",
    "                      :-=*++++++=-:.:::::....           ......             ",
    "                      :-==*+++**+++++++++++++==-:.:-===+++**+-.            ",
    "                      :-==**#**++++++++++++++++++=+++++***+==-             ",
    "           ..::-------==+*##*******++++++++++++++**++**+=====:             ",
    "          .-##%%%%%####****+++++*********++++++++*#**+======-:             ",
    "           :-#%%%%%%#+====**+****+++++++*****+++++##========:              ",
    "            .:=*#%#+======**===+++*****+++++*##**++#+======-:              ",
    "               .-========*=**++++++++******++++*##**#+====-:               ",
    "         -.     -=======*=-=**+++**+++*****##**+++*+=*#+=-:                ",
    "        :::    :-======++=*+++**++#****-:+*:=##****+=-=*-:                 ",
    "          .  .:--==*+=+#+#-::=#+#+*=+*-::+#-:-#++****+=-:.::-. ::.         ",
    "              .:-=+**+**+=:::=#=======-::-#--+#+++++**===+=-:              ",
    "               .:=**#*#*++=-::*+=+=====---==+**++++-=**=---:     ::        ",
    "              :--==*#*++*+++=======++=+=====+#++++=-***=====-:.  -..       ",
    "                 :-====++*+=====++*****=====**+++=--=**-.  .:-.            ",
    "                :-:.  :====-:::-=+*****====**++==:  :=+=-.  .:-.           ",
    "                ..:--:....::::::..=+**++++*+=--=*=--:=-=+-:-:.             ",
    "               :=+++- .::::::::---: :=====-====*+======----=---:.          ",
    "              .=+++= ::... .-===---- .+***#*#**+:----============-:.       ",
    "              :++++::-.::.:*******+-: ******#*===-:...:--===========-:.    ",
    "             .=++++..:.::=**+*******: ********=====:.   .:-============-:. ",
    "             :+++++- ::.=#+++********=#*****+=-=====:.     .:-============-",
    "             :++++++: :=*++++*******###*****-.:=++===-:       :-===========",
    "             :++++++*+=*++++++++***#*##****=: :-++++++-:       .:-=========",
    "             :++++++***++++++++****#**#***+:   :=++++++=:        .-=++=====",
    "             .=++++++#++++++++*****#******-.   :-+++++++=:        .:=++++++",
    "              .::::.:=++++++++*****##****#+:   .:++++++++-.         :-+++++",
    "                  ..-*++++++*******#****#**:    :=++++++++-.         :=++++",
    "              .:-=+++***********#*##***#***-.   :-++++++++=:         .:++++",
    "           .:-=+++++++++++*****************+-   :-+++++++++-.         .-+++",
    "         .*****+++++++++*********************+  :-+++++++++=:         .:+++",
    "         .#%%%##**++++++**********************#.:=++++++++++:.         :-++",
    "           =#%%%%##***********#****************#=+++++++++++:.         ...."
];

const lines = [
    "mirdukkkkk@space:~$ cd /personal/space/",
    "mirdukkkkk@space:/personal/space/$ space --version",
    "",
    "Version: __APP_VERSION__",
    "Author:  mirdukkkkk",
    "",
    "mirdukkkkk@space:/personal/space/$ space preview",
    "Launching personal space...",
    "Expanding space for /niko",
    "",
    ...art,
    "",
    "mirdukkkkk@space:/personal/space/$"
];

export default console.log("%c" + lines.join("\n"), "color: #ee9cff");
